import { Component, Inject, ViewChild } from '@angular/core';
import {
    MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
    MatLegacyDialogRef as MatDialogRef
} from "@angular/material/legacy-dialog";
import { CtModelConfiguration, CtModelService } from "@ctsolution/ct-framework";
import { CtWebapiGenericResponse } from "@ctsolution/ct-webapi";
import { ContractDTO } from "../../../core/class/contract-dto";
import { ContractTypeListElementDTO } from "../../../core/controller/contract-types.controller";
import { ContractExtraFieldComponent } from "../contract-create/contract-extra-field/contract-extra-field.component";
import {
    ContractExtraFieldConfiguration
} from "../contract-create/contract-extra-field/contract-extra-field.configuration";
import { CONTRACT_ROUTEDATA } from "../contract.module";

@Component({
    selector: 'app-specific-contract-detail',
    template: `
        <mat-dialog-content>
            <app-contract-extra-field #ContractExtraField (close)="close()"></app-contract-extra-field>
        </mat-dialog-content>`,
    styles: [`
      .mat-dialog-content {
        margin: 0 0px;
        padding: 0px;
      }

      .mat-dialog-actions {
      }
    `]
})
export class SpecificContractDetailComponent<T> {
    @ViewChild('ContractExtraField') contractExtraField: ContractExtraFieldComponent | null = null;
    private configuration: CtModelConfiguration<ContractDTO<T>> = CtModelConfiguration
        .create<ContractDTO<T>>()
        .setRouteData(CONTRACT_ROUTEDATA());

    constructor(
        private _model: CtModelService<T>,
        private dialogRef: MatDialogRef<SpecificContractDetailComponent<T>>,
        @Inject(MAT_DIALOG_DATA) data: ContractDTO<T>) {

        if (!data.Oid) return;

        this.configuration
            ?.RouteData
            ?.setId(data.Oid);

    }

    ngAfterViewInit() {

        this._model
            .getInfos(this.configuration as CtModelConfiguration<T>)
            ?.subscribe((response: CtWebapiGenericResponse<CtModelConfiguration<ContractDTO<T>>>) => {

                const value = response.Result.DataSource as ContractDTO<T>;

                if (!value) return;

                setTimeout(() => this.setupContractValue(value));

            });

    }

    setupContractValue(value: ContractDTO<T>) {

        if (value.SpecificContract && value.ContractType) {
            var unknownSpecificContract = value.SpecificContract as any;
            if(unknownSpecificContract['lstSoftwareSubscriptionRowDTO'] && unknownSpecificContract['lstSoftwareSubscriptionRowDTO'] instanceof Array) {
                var lomSpecificContract = unknownSpecificContract['lstSoftwareSubscriptionRowDTO'];
                lomSpecificContract.forEach((element: any) => {
                    if(isNaN(element.InstancePrice) == false){
                        
                        element.InstancePrice = Number(element.InstancePrice).toFixed(2);
                    }
                    if(isNaN(element.FinalPrice) == false){
                        element.FinalPrice = Number(element.FinalPrice).toFixed(2);
                    }
                });
            }

            this.contractExtraField?.setFormValue(value.SpecificContract);

            const extraFieldConfiguration: ContractExtraFieldConfiguration = ContractExtraFieldConfiguration.create()
                .setContractType({
                    Code: value.ContractType.Oid.toString(),
                    Description: value.ContractType.Name
                } as ContractTypeListElementDTO)
                .setDisabled(true)
                .setActionsEnabled(true);

            this.contractExtraField
                ?.setup(extraFieldConfiguration)

        } else {

            console.warn("CT Model: no specific contract found")
        }

    }

    close() {

        this.dialogRef
            .close();

    }

}
